<template>
    <div class="frame">
        <div class="container">
            <h1 class="title">회원정보</h1>
            <div class="info-box">
                <div class="info-header">
                    <h5 class="info-title">
                        회원정보 수정 </h5>
                </div>
                <div class="line"/>
                <div class="info-center">
                    <div class="left">
                        <h2>안녕하세요<br/>관리자님 반갑습니다!</h2>
                    </div>
                    <div class="right">
                        <div class="input-box">
                            <p>아이디(이메일)</p>
                            <input type="email" v-model="email" disabled>
                        </div>
                        <div class="input-box">
                            <p>담당자명<strong>*</strong></p>
                            <div class="hide">
                                <input/>
                                <input type="password">
                            </div>
                            <input type="text" v-model="masterName" maxlength="8">
                        </div>
                        <div class="input-box">
                            <p>담당자 연락처<strong>*</strong></p>
                            <input v-model="phone" type="tel" placeholder="예) 010-1234-1234" maxlength="13">
                        </div>
                        <div class="input-box">
                            <p>최근 접속일</p>
                            <input v-model="lastLog" type="tel" disabled>
                        </div>
                        <div class="input-box">
                            <p>비밀번호 수정</p>
                            <div class="icon-input">
                <span v-if="newPassword!==''">
                  <i class="far fa-eye-slash" v-if="!passwordShow" @click="passwordShow=true"></i>
                  <i class="far fa-eye" v-show="passwordShow" @click="passwordShow=false"></i>
                </span>
                                <input :type="[passwordShow ? 'text' : 'password']" placeholder="새로운 비밀번호(영문+숫자 8자리 이상)"
                                       v-model="newPassword">
                            </div>
                            <div class="icon-input">
                <span v-if="newPasswordCheck!==''">
                  <i class="far fa-eye-slash" v-if="!checkPasswordShow" @click="checkPasswordShow=true"></i>
                  <i class="far fa-eye" v-show="checkPasswordShow" @click="checkPasswordShow=false"></i>
                </span>
                                <input :type="[checkPasswordShow ? 'text' : 'password']" placeholder="새로운 비밀번호 확인"
                                       v-model="newPasswordCheck">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="line"/>
                <div class="info-footer">
                    <button class="custom-btn-box btn-edit" @click="editBtn">수정</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getDateTimeSec} from "@/lib/prettyDate";
import {auth, firestore} from "@/firebase/firebaseConfig";

export default {
    name: "MasterMyInfo",
    mounted() {
        this.init()
    },
    data() {
        return {
            fbCollection: 'master',
            email: '',
            masterName: '',
            phone: '',
            lastLog: '',

            newPassword: '',
            newPasswordCheck: '',
            passwordShow: false,
            checkPasswordShow: false
        }
    },
    methods: {
        init() {
            const self = this;
            const userEmail = auth.currentUser.email
            firestore.collection(self.fbCollection)
                .where('email', '==', userEmail)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        self.email = doc.data().email
                        self.masterName = doc.data().masterName
                        self.phone = doc.data().phone

                        const date = new Date(doc.data().lastLog.seconds * 1000);
                        self.lastLog = getDateTimeSec(date)
                    })
                })
        },
        checkMobile() {
            const self = this;
            const exptext = /^\d{3}\d{3,4}\d{4}$/;
            const exptext2 = /^\d{3}-\d{3,4}-\d{4}$/;
            if (exptext.test(self.phone) === false && exptext2.test(self.phone) === false) {
                self.$swal.fire({title: '형식 오류', text: '전화번호 형식이 올바르지 않습니다.', icon: 'error'})
                return false;
            }
            self.phone = self.phone
                .replace(/[^0-9]/g, "").replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/, "$1-$2-$3");
            return true;
        },
        checkPassword() {
            const self = this;
            const exptext = new RegExp("^(?=.*[0-9])(?=.*[A-Za-z])^[a-zA-Z0-9]{8,}$");
            if (exptext.test(self.newPassword) === false) {
                self.$swal.fire({title: '형식 오류', text: '영문/숫자 포함 8자리로 설정해주세요.', icon: 'error'})
                return false;
            }
            return true;
        },
        editBtn() {
            const self = this;
            const user = auth.currentUser;

            //필수입력 확인
            if (self.masterName.trim().length === 0) {
                self.$swal.fire({title: '입력 필요', text: '관리자명 입력해주세요', icon: 'error'})
                return
            } else if (self.phone.trim().length === 0) {
                self.$swal.fire({title: '입력 필요', text: '관리자 연락처를 입력해주세요', icon: 'error'})
                return
            }
            //비밀번호 수정 확인
            if (self.newPassword.trim().length !== 0 || self.newPasswordCheck.trim().length !== 0) {
                if (self.newPasswordCheck === '') {
                    self.$swal.fire({title: '입력 필요', text: '비밀번호 확인란을 입력해주세요', icon: 'error'})
                    return
                } else if (self.newPassword !== self.newPasswordCheck) {
                    self.$swal.fire({title: '입력 필요', text: '입력한 비밀번호가 다릅니다', icon: 'error'})
                    return
                } else if (self.newPassword.length < 8 || self.newPasswordCheck.length < 8) {
                    self.$swal.fire({title: '입력 필요', text: '8자리 이상의 비밀번호로 설정해주세요', icon: 'error'})
                    return
                }
                if (!self.checkPassword()) {
                    return false
                }
            }
            if (!self.checkMobile()) {
                return false
            }

            const masterData = {
                masterName: self.masterName,
                phone: self.phone,
            }
            const batch = firestore.batch();

            const masterRef = firestore.collection(self.fbCollection).doc(user.uid);
            batch.update(masterRef, masterData)

            batch.commit()
                .then(() => {
                    if (self.newPassword.length * self.newPasswordCheck.length !== 0 && self.newPassword === self.newPasswordCheck) {
                        user.updatePassword(self.newPassword)
                        self.$swal.fire({title: '변경 완료', text: '변경한 비밀번호로 재로그인 해주세요.', icon: 'success', closeOnClickOutside: false})
                            .then(() => {
                                auth.signOut();
                            })
                        return
                    }
                    self.$swal.fire({title: '수정 완료', text: '관리자 회원정보가 수정되었습니다.', icon: 'success', closeOnClickOutside: false})
                })
        }
    }
}
</script>

<style scoped>
.frame {
    min-width: 1190px;
    overflow: hidden;
    height: 100vh;
    width: 100%;
    background: #F4F4F5;
    padding: 40px;
}

.container {
    margin: 0 auto;
    width: 1110px;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.title {
    /*font-family: 'Roboto',serif;*/
    font-family: 'Noto Sans KR', serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 20px;
}

.info-box {
    width: 1110px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: #FFFFFF;
    /*font-family: 'Roboto',serif;*/
    font-family: 'Noto Sans KR', serif;
    font-size: 14px;
}

/* header */
.info-header {
    height: 68px;
    border-radius: 8px 8px 0 0;
    padding: 24px 20px 25px;
}

.info-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    margin: 0;
}

.line {
    background: #E6E7E9;
    height: 1px;
}

/* center */
.info-center {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.info-center > div {
    width: 527px;
    height: 480px;
}

.info-center .left {
    padding: 74px 64px 64px;
    color: #71717A;
}

.info-center .left h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
}

.info-center .left {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 14px;
    color: #000000;
    margin: 136px 0 30px;
}

.info-center .left .link a {
    display: flex;
    justify-items: center;
    align-items: center;
    margin-bottom: 10px;
}

.info-center .left .link p {
    width: 110px;
    margin-right: 2px;
}

.info-center .left > p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.info-center .right .input-box p {
    font-weight: 600;
    font-size: 13px;
    line-height: 140%;
    margin-bottom: 10px;
}

.info-center .right .input-box strong {
    color: #BE123C;
}

.info-center .right .input-box input {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px 16px 10px;
}

input {
    outline: none;
    border: 1px solid #A1A1AA;
    border-radius: 4px;
}

input::placeholder {
    color: #A1A1AA;
}

input[disabled] {
    color: #71717A;
}

.icon-input {
    position: relative;
}


.icon-input i {
    position: absolute;
    top: 25%;
    right: 20px;
}
.hide {
  position: absolute;
  z-index: -10;
}

/* footer */
.info-footer {
    background: #FFFFFF;
    border-radius: 0 0 8px 8px;
    height: 73px;
    padding: 17px 16px 16px;
    margin-left: auto;
}

.info-footer .btn-edit {
    margin: 0;
    padding: 11px 12px;
    width: 67px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    border: 0;
}
</style>